<template>
  <div class="layout-sct2">
    <main class="">
      <slot />
    </main>
    <nav-bar-footer />
  </div>
</template>

<script>
import NavBarFooter from "@/components/layout/TheNavBarFooter";

export default {
  name: "LayoutSCT2",
  components: {
    NavBarFooter,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.layout-sct2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
}
</style>