<template>
  <!-- <div v-if="mode_param.requested_mode" class="sct2-requested-container">
    <img class="sct2-requested-img" src="../assets/icons/setting.png" />
    <div class="sct2-requested-title">{{ $t('requested_mode_message') | capitalize }}</div>
  </div> -->
  <div class="sct2-parameters-container">
    <div class="sct2-parameters-title">
      {{ $t("sct2_parameters_title") | capitalize }}
    </div>
    <div class="sct2-range">
      <div class="sct2-range-title">
        {{ $t("frequencies") | capitalize }}
      </div>
      <div class="sct2-range-description">
        {{ $t("frequencies_range_description") }}
      </div>
      <div class="sct2-range-buttonsgroup">
        <b-button
          @click="
            (openExpand = personalized = false),
              (frequenciesMoving = 5),
              (frequenciesStoped = 4)
          "
          :class="{
            active:
              frequenciesMoving == 5 && frequenciesStoped == 4 && !personalized,
          }"
          class="sct2-range-button"
        >
          <div class="sct2-range-button-content">
            {{ $t("high") | capitalize }}:
            {{ $t("everyMinutesRange", { n: 5, m: 7 }) | capitalize }}
            <check
              v-if="
                frequenciesMoving == 5 &&
                frequenciesStoped == 4 &&
                !personalized
              "
            />
          </div>
        </b-button>
        <b-button
          @click="
            (openExpand = personalized = false),
              (frequenciesMoving = 15),
              (frequenciesStoped = 4)
          "
          :class="{
            active:
              frequenciesMoving == 15 &&
              frequenciesStoped == 4 &&
              !personalized,
          }"
          class="sct2-range-button"
        >
          <div class="sct2-range-button-content">
            {{ $t("medium") | capitalize }}:
            {{ $t("everyMinutesRange", { n: 15, m: 20 }) | capitalize }}
            <check
              v-if="
                frequenciesMoving == 15 &&
                frequenciesStoped == 4 &&
                !personalized
              "
            />
          </div>
        </b-button>
        <b-button
          @click="
            (openExpand = personalized = false),
              (frequenciesMoving = 30),
              (frequenciesStoped = 4)
          "
          :class="{
            active:
              frequenciesMoving == 30 &&
              frequenciesStoped == 4 &&
              !personalized,
          }"
          class="sct2-range-button"
        >
          <div class="sct2-range-button-content">
            {{ $t("standard") | capitalize }}:
            {{ $t("everyMinutesRange", { n: 30, m: 35 }) | capitalize }}
            <check
              v-if="
                frequenciesMoving == 30 &&
                frequenciesStoped == 4 &&
                !personalized
              "
            />
          </div>
        </b-button>
        <b-button
          :class="{
            active:
              personalized &&
              !openExpand &&
              isFrequenciesMovingValid &&
              isFrequenciesStopedValid,
            'is-invalid':
              !openExpand &&
              (!isFrequenciesMovingValid || !isFrequenciesStopedValid),
          }"
          class="sct2-range-button button-expand"
        >
          <div @click="openExpand = !openExpand" class="button-expand-header">
            <div class="button-expand-title">
              {{ $t("personalized") | capitalize }}
            </div>
            <chevron :iconDirection="'up'" :class="{ active: openExpand }" />
          </div>
          <app-transition-expand>
            <div
              v-if="!openExpand && personalized == true"
              class="sct2-expand-summary"
            >
              <div
                class="expand-summary-item"
                :class="{
                  'is-invalid': !isFrequenciesMovingValid,
                }"
              >
                {{ $t("moving") | capitalize }}:
                {{ $tc("minuteKey", frequenciesMoving) }}
              </div>
              <div
                v-if="!isFrequenciesMovingValid"
                class="expand-summary-item sct2-alert-text"
              >
                {{ inputAlertText("betweenMinutesRange", 1, 180) }}
              </div>
              <div
                class="expand-summary-item"
                :class="{
                  'is-invalid': !isFrequenciesStopedValid,
                }"
              >
                {{ $t("stoped") | capitalize }}:
                {{ $tc("hourKey", Math.round(frequenciesStoped)) }}
              </div>
              <div
                v-if="!isFrequenciesStopedValid"
                class="expand-summary-item sct2-alert-text"
              >
                {{ inputAlertText("betweenHoursRange", 1, 24) }}
              </div>
            </div>
          </app-transition-expand>
          <app-transition-expand>
            <div v-if="openExpand">
              <div class="button-expand-body">
                <div class="button-expand-inputgroup">
                  <div class="button-expand-label">
                    {{ $t("moving") | capitalize }} ({{ $t("minutes") }})
                  </div>
                  <b-form-input
                    @click="personalized = true"
                    type="number"
                    :state="isFrequenciesMovingValid"
                    :placeholder="
                      inputPlaceholder('betweenMinutesRange', 1, 180)
                    "
                    v-model="frequenciesMoving"
                    min="1"
                    max="180"
                    class="button-expand-input"
                  />
                  <app-transition-expand>
                    <div
                      v-if="!isFrequenciesMovingValid"
                      class="sct2-alert-text"
                    >
                      {{ inputAlertText("betweenMinutesRange", 1, 180) }}
                    </div>
                  </app-transition-expand>
                </div>
                <div class="button-expand-inputgroup">
                  <div class="button-expand-label">
                    {{ $t("stoped") | capitalize }} ({{ $t("hours") }})
                  </div>
                  <b-form-input
                    @click="personalized = true"
                    type="number"
                    :state="isFrequenciesStopedValid"
                    :placeholder="inputPlaceholder('betweenHoursRange', 1, 24)"
                    v-model="frequenciesStoped"
                    min="1"
                    max="24"
                    class="button-expand-input"
                  />
                  <app-transition-expand>
                    <div
                      v-if="!isFrequenciesStopedValid"
                      class="sct2-alert-text"
                    >
                      {{ inputAlertText("betweenHoursRange", 1, 24) }}
                    </div>
                  </app-transition-expand>
                </div>
              </div>
            </div>
          </app-transition-expand>
        </b-button>
      </div>
    </div>
    <div class="sct2-range">
      <div class="sct2-range-title">
        {{ $t("temperatures") | capitalize }}
      </div>
      <div class="sct2-range-description">
        {{ $t("temperatures_range_description") }}
      </div>
      <div class="sct2-range-buttonsgroup">
        <!-- <b-button
          @click="temperatures = 5"
          :class="{ active: temperatures == 5 }"
          class="sct2-range-button"
        >
          <div class="sct2-range-button-content">
            {{ $t("high") | capitalize }}:
            {{ $t("everyMinutes", { n: 5 }) | capitalize }}
            <check v-if="temperatures == 5" />
          </div>
        </b-button> -->
        <b-button
          @click="temperatures = 60"
          :class="{ active: temperatures == 60 }"
          class="sct2-range-button"
        >
          <div class="sct2-range-button-content">
            {{ $t("medium") | capitalize }}:
            {{ $t("everyHour", { n: 1 }) | capitalize }}
            <check v-if="temperatures == 60" />
          </div>
        </b-button>
        <b-button
          @click="temperatures = 720"
          :class="{ active: temperatures == 720 }"
          class="sct2-range-button"
        >
          <div class="sct2-range-button-content">
            {{ $t("standard") | capitalize }}:
            {{ $t("everyHours", { n: 12 }) | capitalize }}
            <check v-if="temperatures == 720" />
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppTransitionExpand from "../components/ui/AppTransitionExpand.vue";
import Chevron from "../svg/chevron.vue";
import Check from "../svg/check.vue";
import LayoutSCT2 from "../layouts/LayoutSCT2.vue";

export default {
  components: { AppTransitionExpand, Chevron, Check },
  name: "History",

  data() {
    return {
      openExpand: false,
      personalized: false,

      mode_param: null,
      frequenciesMoving: 0,
      isFrequenciesMovingValid: true,
      frequenciesStoped: 0,
      isFrequenciesStopedValid: true,
      temperatures: 0,
    };
  },

  computed: {
    ...mapGetters("devices", ["getDevices", "getSelectedDevice"]),
    ...mapGetters("parameters", ["getSct2Params"]),
  },

  watch: {
    frequenciesMoving(newFrequenciesMoving) {
      this.mode_param.frequency = Math.round(newFrequenciesMoving);
      this.isFrequenciesMovingValid =
        newFrequenciesMoving >= 1 && newFrequenciesMoving <= 180;
      this.setSct2Params(this.mode_param);
    },
    frequenciesStoped(newFrequenciesStoped) {
      this.mode_param.keepalive_period = Math.round(newFrequenciesStoped) * 60;
      this.isFrequenciesStopedValid =
        newFrequenciesStoped * 60 >= 1 && newFrequenciesStoped * 60 <= 1440;
      this.setSct2Params(this.mode_param);
    },
    temperatures(newTempFrequency) {
      this.mode_param.temp_slice_duration = newTempFrequency;
      this.mode_param.temp_measure_period = newTempFrequency * 5;
      this.mode_param.temp_uplink_slices_duration = 2;
      this.setSct2Params(this.mode_param);
    },
    openExpand(newValue) {
      if (!newValue) {
        this.frequenciesMoving = Math.round(this.frequenciesMoving);
        this.frequenciesStoped = Math.round(this.frequenciesStoped);
      }
    },
  },

  methods: {
    ...mapActions("parameters", ["fetchSct2Params", "setSct2Params"]),
    ...mapActions("devices", ["fetchDevices", "setSelectedDevice"]),
    ...mapActions("devices", ["removeDevices"]),
    ...mapActions("devices", ["removeSelectedDevice"]),
    ...mapActions("users", ["setToken"]),

    inputPlaceholder(i18nKey, min, max) {
      return this.$options.filters.capitalize(
        this.$i18n.t(i18nKey, { n: min, m: max })
      );
    },

    inputAlertText(i18nKey, min, max) {
      return (
        this.$options.filters.capitalize(this.$i18n.t("mustBe")) +
        " " +
        this.$i18n.t(i18nKey, { n: min, m: max })
      );
    },

    async getModeParam() {
      this.mode_param = await this.getSct2Params;
      if (!this.mode_param.temp_uplink_slices_duration) {
        this.mode_param.temp_uplink_slices_duration = 2;
      }
      this.frequenciesMoving = this.mode_param.frequency;
      this.frequenciesStoped = this.mode_param.keepalive_period / 60;
      this.temperatures = this.mode_param.temp_slice_duration;
      if (
        (this.frequenciesMoving != 5 &&
          this.frequenciesMoving != 15 &&
          this.frequenciesMoving != 30) ||
        this.frequenciesStoped != 4
      ) {
        this.personalized = true;
      }
    },
  },

  async mounted() {
    window.zE("webWidget", "hide");
  },

  async created() {
    this.personalized = false;

    let parameters = this.$route.query;
    if (parameters["token"]) {
      let token = parameters["token"];
      this.setToken(token);
    }

    await this.removeDevices();
    await this.removeSelectedDevice();
    await this.fetchDevices();

    if (parameters["serial"]) {
      await this.setSelectedDevice(
        this.getDevices.filter(
          (device) => device.serial == parameters["serial"]
        )[0]
      );
    }
    if (this.getSelectedDevice === null && this.getDevices.length != 0) {
      await this.setSelectedDevice(this.getDevices[0]);
    }
    if (this.getSelectedDevice) {
      await this.fetchSct2Params(this.getSelectedDevice.id);
      await this.getModeParam();
    }
    if (this.mode_param.requested_mode) {
      this.$emit("update:layout", LayoutSCT2);
    } else {
      this.$emit("update:layout", LayoutSCT2);
    }
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.sct2-requested {
  &-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 2em;
    justify-content: center;
    align-items: center;
  }

  &-img {
    width: 140px;
    height: 140px;
  }

  &-title {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 28px;
    text-align: center;
  }
}

.sct2-parameters-container {
  margin-bottom: 9em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  .sct2-parameters-title {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 28px;
    text-align: left;
  }

  .sct2-alert-text {
    text-align: left;
    color: #f95658 !important;
    font-weight: 500 !important;
  }

  .sct2-range {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &-title {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 18px;
      color: #050328;
    }

    &-description {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 14px;
    }

    &-buttonsgroup {
      display: flex;
      flex-direction: column;
      gap: 0.5em;

      .button-expand {
        padding: 0em 1em !important;

        &-header {
          padding: 1em 0em;
        }
      }

      .sct2-range-button {
        background: #ffffff;
        border: 1px solid #eaeaea;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 1em;

        &:focus {
          box-shadow: none;
        }

        &-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #050328;
          font-family: "Open Sans", sans-serif;
          font-weight: 600;
          font-size: 14px;
          text-align: left;
        }
      }

      .btn-secondary:not(:disabled):not(.disabled).is-invalid {
        border: 1px solid #f95658 !important;
        background: #feeeee;
        box-shadow: 0px 0px 0px 1px #feeeee !important;
      }

      .btn-secondary:not(:disabled):not(.disabled).active {
        border: 1px solid #0090f8 !important;
        box-shadow: 0px 0px 0px 1px #0090f8 !important;
      }

      .sct2-expand-summary {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        .expand-summary-item {
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 14px;
          color: #555b62;
          text-align: left;

          &:last-child {
            padding-bottom: 1em;
          }
        }

        .expand-summary-item.is-invalid {
          color: #f95658 !important;
          font-weight: 500 !important;
          border: none !important;
          background: transparent !important;
        }
      }

      .button-expand-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .button-expand {
        &-title {
          color: #050328;
          font-family: "Open Sans", sans-serif;
          font-weight: 600;
          font-size: 14px;
          text-align: left;
        }

        &-body {
          display: flex;
          flex-direction: column;
          gap: 0.5em;
          border-top: #eaeaea 2px solid;
          padding: 1em 0em;
        }

        &-inputgroup {
          display: flex;
          flex-direction: column;
        }

        &-label {
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 14px;
          color: #555b62;
          text-align: left;
        }

        &-input {
          background: #ffffff;
          border: 1px solid #8b949f;
          box-sizing: border-box;
          border-radius: 8px;
          font-family: "Open Sans";
          font-size: 14px;
          padding: 0.75em;
          margin-top: 0.5em;

          &:focus-visible {
            border: 1px solid #0090f8 !important;
            box-shadow: 0px 0px 0px 1px #0090f8 !important;
            outline: none;
            background: white;
          }
        }

        .is-invalid {
          border: 1px solid #f95658 !important;
          background: #feeeee;

          &:focus-visible {
            border: 1px solid #f95658 !important;
            box-shadow: 0px 0px 0px 1px #feeeee !important;
          }
        }
      }
    }
  }
}
</style>