<template>
  <footer class="sct2-footer">
    <div v-if="getBatteryLife" class="sct2-footer-information">
      <div class="sct2-footer-text">
        {{ $t("estimated_batteryLife") | capitalize }}:
        {{ inputBatteryLifeText('betweenMonthsRange', getBatteryLife.min, getBatteryLife.max) | capitalize }}
      </div>
      <information @click.native="$bvModal.show('bv-modal-example')" />
    </div>
    <b-button
      class="b-btn-checkout sct2-footer-button"
      block
      variant="primary"
      :disabled="!getIsParamsValid"
      @click="sct2ParametersValidation()"
    >
      <div class="sct2-footer-button-text" v-if="!isValidated">
        {{ $t("Save") }}
      </div>
      <div class="sct2-footer-button-text" v-else>
        {{ $t("Saved") }}
        <check :iconColor="'white'" />
      </div>
    </b-button>
    <b-modal
      :hide-header="true"
      :hide-footer="true"
      centered
      scrollable
      id="bv-modal-example"
    >
      <div class="sct2-modal-body">
        <cross @click.native="$bvModal.hide('bv-modal-example')" class="sct2-modal-cross" />
        <img class="sct2-modal-img" src="../../assets/icons/battery.png" />
        <div class="sct2-modal-title">
          {{ $t("estimated_batteryLife") | capitalize }}:
        </div>
        <div class="sct2-modal-content">
          <div>
            {{ $t('sct2_modal_content') }}
          </div>
          <div class="sct2-modal-content-major">
            {{ $t('minBatteryLife') | capitalize }}:
          </div>
          <div>
            {{ $tc("hoursPerDay", 8) }}
          </div>
          <div class="sct2-modal-content-major">
            {{ $t('maxBatteryLife') | capitalize }}:
          </div>
          <div >
            {{ $tc("hoursPerDay", 2) }}
          </div>
        </div>
      </div>
    </b-modal>
  </footer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Check from "../../svg/check.vue";
import Cross from "../../svg/cross.vue";
import information from "../../svg/information.vue";

export default {
  components: { information, Check, Cross },

  name: "TheNavBarFooter",

  data() {
    return {
      isValidated: false,
    };
  },

  computed: {
    ...mapGetters("devices", ["getSelectedDevice"]),
    ...mapGetters("parameters", ["getSct2Params", "getBatteryLife", "getIsParamsValid"]),
  },

  methods: {
    ...mapActions("parameters", ["patchSct2Params"]),

    async sct2ParametersValidation() {
      if (this.getSelectedDevice) {
        await this.patchSct2Params({
          sct2_id: this.getSelectedDevice.id,
          sct2_mode_params: await this.getSct2Params,
        });
      }
      this.isValidated = true;

      setTimeout(() => {
        this.isValidated = false;
      }, 3000);
    },

    inputBatteryLifeText(i18nKey, min, max) {
      return (
        this.$i18n.t(i18nKey, { n: min, m: max })
      );
    }
  },
};
</script>

<style lang="scss">
.modal-dialog {
  margin: 1em !important;
}
</style>

<style lang="scss" scoped>
.sct2-modal {
  &-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    padding: 1em;
    padding-bottom: 3em;
  }

  &-cross {
    align-self: flex-end;
  }

  &-img {
    width: 140px;
    height: 140px;
  }

  &-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    color: #050328;
    text-align: center;
  }

  &-content {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #050328;
    text-align: center;

    &-major {
      font-weight: 600 !important;
    }
  }
}
.sct2-footer {
  height: auto;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1em;

  background: #ffffff;
  box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  &-information {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-text {
    font-weight: 500;
    font-size: 16px;
    color: #1b1b1b;
  }

  &-button {
    background: #0090f8;
    border: 1px solid #0090f8;
    box-shadow: 0px 0px 10px rgba(0, 144, 248, 0.25);
    backdrop-filter: blur(80px);
    border-radius: 8px;
    padding: 1em 0;

    &-text {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
    }

    &:focus {
      box-shadow: none;
      border: 1px solid #0090f8;
    }

    &:active {
      background: #0090f8 !important;
      box-shadow: 0px 0px 10px rgba(0, 144, 248, 0.25) !important;
      backdrop-filter: blur(80px) !important;
      border-radius: 8px;
      border: 1px solid #0090f8;
    }

    &:disabled {
      background: lightslategrey;
      border: 1px solid lightslategrey;
    }
  }
}
</style>