<template>
    <svg class="close-cross" :height="iconSize" :fill="iconColor" viewBox="0 0 24 24">
        <path d="M0.619563 20.1375C-0.189647 20.9609 -0.232998 22.4777 0.648463 23.3444C1.52992 24.2256 3.03274 24.1967 3.8564 23.3733L12.0063 15.2259L20.1418 23.3589C20.9943 24.2256 22.4682 24.2112 23.3352 23.33C24.2167 22.4632 24.2167 20.9898 23.3641 20.1375L15.2287 12.0045L23.3641 3.85707C24.2167 3.00477 24.2167 1.5313 23.3352 0.664551C22.4682 -0.216642 20.9943 -0.216642 20.1418 0.635659L12.0063 8.76863L3.8564 0.621214C3.03274 -0.18775 1.51547 -0.231087 0.648463 0.650105C-0.218547 1.5313 -0.189647 3.03366 0.619563 3.85707L8.76946 12.0045L0.619563 20.1375Z"/>
    </svg>
</template>

<script>
export default {
    name: 'Cross',
    props: {
        iconColor: {
            type: String,
            default: '#5B74F4'
        },
        iconSize: {
            type: Number,
            default: 20,
        }
    }
}
</script>

<style lang="scss" scoped>
.close-cross {
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
        transform: rotate(180deg);
    }
}