<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 0C3.36454 0 0 3.36454 0 7.5C0 11.6355 3.36454 15 7.5 15C11.6355 15 15 11.6355 15 7.5C15 3.36454 11.6355 0 7.5 0ZM11.4032 4.98642L6.55709 10.7556C6.50393 10.819 6.43779 10.8701 6.36313 10.9056C6.28848 10.9412 6.20706 10.9602 6.1244 10.9615H6.11466C6.0338 10.9615 5.95385 10.9445 5.87999 10.9116C5.80613 10.8786 5.74002 10.8306 5.68594 10.7704L3.60901 8.46274C3.55627 8.4068 3.51524 8.34087 3.48833 8.26884C3.46143 8.19681 3.44919 8.12013 3.45235 8.04331C3.4555 7.96649 3.47398 7.89107 3.5067 7.82148C3.53941 7.7519 3.58571 7.68956 3.64286 7.63813C3.70002 7.58669 3.76688 7.54721 3.83951 7.52198C3.91214 7.49675 3.98909 7.4863 4.06582 7.49124C4.14255 7.49617 4.21752 7.5164 4.28632 7.55072C4.35512 7.58504 4.41637 7.63277 4.46647 7.69111L6.09952 9.50553L10.5198 4.24435C10.619 4.12972 10.7593 4.0587 10.9104 4.04666C11.0614 4.03463 11.2112 4.08253 11.3273 4.18001C11.4433 4.2775 11.5163 4.41674 11.5306 4.56764C11.5448 4.71853 11.499 4.86897 11.4032 4.98642Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Check",
  props: {
    iconColor: {
      type: String,
      default: "#0090F8",
    },
  },
};
</script>

<style scoped>
svg {
  margin: auto;
}
</style>