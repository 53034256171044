<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_463_13190)">
      <path
        d="M7.82812 1C4.05744 1 1 4.05744 1 7.82812C1 11.5988 4.05744 14.6562 7.82812 14.6562C11.5988 14.6562 14.6562 11.5988 14.6562 7.82812C14.6562 4.05744 11.5988 1 7.82812 1Z"
        stroke="#242424"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M6.78906 6.78906H7.97656V11.0938"
        stroke="#242424"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.34375 11.2422H9.60938"
        stroke="#242424"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M7.86327 3.44922C7.66548 3.44922 7.47214 3.50787 7.3077 3.61775C7.14325 3.72763 7.01507 3.88381 6.93939 4.06654C6.8637 4.24926 6.8439 4.45033 6.88248 4.64431C6.92107 4.83829 7.01631 5.01647 7.15616 5.15633C7.29601 5.29618 7.47419 5.39142 7.66818 5.43C7.86216 5.46859 8.06322 5.44879 8.24595 5.3731C8.42867 5.29741 8.58485 5.16924 8.69473 5.00479C8.80462 4.84034 8.86326 4.647 8.86326 4.44922C8.86326 4.184 8.75791 3.92965 8.57037 3.74211C8.38284 3.55458 8.12848 3.44922 7.86327 3.44922V3.44922Z"
        fill="#242424"
      />
    </g>
    <defs>
      <clipPath id="clip0_463_13190">
        <rect width="15.6562" height="15.6562" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
</style>